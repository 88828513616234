<template>
    <v-container ref="pageSiteBrands" fluid tag="section">

        <material-card icon="mdi-clipboard-text" icon-small title="Бренди сайтів" color="accent" class="mb-16">
            <!--Фильтры-->
            <v-row class="mx-2 mb-2">
                <v-col cols="12" md="3">
                    <v-select return-object item-text="name" item-value="name" v-model="project" :items="projects"
                        label="Проект" clearable @change="getSiteBrands()"></v-select>
                </v-col>
            </v-row>
            <v-container fluid fill-height v-if="project && project.id">
                <v-row>
                    <v-col>
                        <v-data-table :headers="categoriesBrandHeaders" :items="site_brands" sort-by="ord"
                            class="elevation-1">
                            <template v-slot:top>
                                <v-toolbar flat>
                                    <v-toolbar-title>Бренди</v-toolbar-title>
                                    <v-divider class="mx-4" inset vertical></v-divider>
                                    <v-spacer></v-spacer>
                                    <v-btn color="primary" @click="addBrand()">Додати бренд</v-btn>
                                </v-toolbar>
                            </template>
                            <template v-slot:item.image="{ item }">
                                <v-img :src="`${imageBrandsBaseUrl}${item.image}`" max-height="50"
                                    max-width="50"></v-img>
                            </template>
                            <template v-slot:item.ord="props">
                                <v-edit-dialog :return-value.sync="props.item.ord" large persistent
                                    @save="saveBrandOrd(props.item)" save-text="Зберегти" cancel-text="Повернутись">
                                    <div>{{ props.item.ord }}</div>
                                    <template v-slot:input>
                                        <div class="mt-4 text-h6">
                                            Змінити сортування
                                        </div>
                                        <v-text-field v-model="props.item.ord" label="Редагувати" single-line counter
                                            autofocus></v-text-field>
                                    </template>
                                </v-edit-dialog>
                            </template>
                            <template v-slot:item.action="{ item }">
                                <v-btn icon @click="deleteBrand(item)">
                                    <v-icon color="red">mdi-delete</v-icon>
                                </v-btn>
                            </template>
                        </v-data-table>
                    </v-col>
                </v-row>
            </v-container>

            <!--Диалоговое окно для удаления-->
            <DialogConfitmDelete ref="dialogConfirmDelete" />
            <v-dialog v-model="dialogAddBrand" max-width="500">
                <v-card>
                    <v-card-title>Додати бренд</v-card-title>
                    <v-card-text>
                        <v-autocomplete item-text="name" item-value="id" v-model="brand.brand_id" :items="brands"
                            label="Бренд" required></v-autocomplete>
                        <v-text-field label="Позиція" v-model="brand.ord" required></v-text-field>
                    </v-card-text>
                    <v-card-actions>
                        <v-btn color="primary" @click="saveBrand()">Зберегти</v-btn>
                        <v-btn color="error" @click="dialogAddBrand = false">Скасувати</v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
            <Preloader v-if="isSending" />
        </material-card>
    </v-container>
</template>



<script>
import {
    HTTP
} from '@/http-common';
import {
    mapGetters,
    mapMutations,
    mapActions
} from 'vuex'
import { VueEditor } from "vue2-editor";
import { IMAGE_BASE_URL } from '@/env.config';

export default {
    name: 'RegularTablesView',
    components: {
        VueEditor
    },

    data() {
        return {
            isValidForm: true,
            brands: [],
            project: {},
            site_brands: [],
            mis: null,
            // Выполняется отправка на сервер
            isSending: false,
            errorRequired: `Це поле обов'язкове`,
            imageBrandsBaseUrl: `${IMAGE_BASE_URL}brands/`,
            categoriesBrandHeaders: [
                {
                    text: 'ID',
                    align: 'start',
                    sortable: false,
                    value: 'id',
                },
                { text: 'Бренд', value: 'name' },
                { text: 'Зображення', value: 'image' },
                { text: 'Позиція', value: 'ord' },
                { text: 'Управління', value: 'action' },
            ],
            dialogAddBrand: false,
            brand: {
                brand_id: 0,
                ord: 0,
                category_id: 0,
            }
        }
    },
    computed: {
        ...mapGetters(['isManager', 'isAdmin', 'projects', 'localization']),
    },

    mounted() {
        this.init();
    },
    watch: {
        '$route': {
            handler() {
            },
            deep: true,
        }
    },

    methods: {
        ...mapMutations(['setSnackbar']),
        ...mapActions(['getProjects', 'getLocalization']),

        async init() {
            await this.getProjects();
            await this.getLocalization();
            await this.getBrands();
        },

        // Получить каталог
        async getCatalog() {
            try {
                const {
                    data
                } = await HTTP.post('/get-catalog', {
                    project: this.project.id
                });
                this.catalog = data?.content?.catalog || [];
            } catch (error) {
                console.warn(error);
            }
        },
        async getSiteBrands() {
            try {
                if (this.project && this.project.id) {
                    const {
                        data
                    } = await HTTP.post('/get-site-brands', {
                        project: this.project.id
                    });
                    this.site_brands = data?.content || [];

                }
            } catch (error) {
                console.warn(error);
            }
        },
        changeRoute(tabId) {
            if (this.$route.query.tab != tabId) {
                this.$router.push({ query: { tab: tabId } });
            }
        },
        async saveBrandOrd(item) {
            const {
                data
            } = await HTTP.post('/update-site-brand', item);
        },
        async deleteBrand(item) {
            const {
                data
            } = await HTTP.post('/delete-site-brand', item);
            this.site_brands = this.site_brands.filter(brand => brand.id != item.id);
        },
        addBrand() {
            this.dialogAddBrand = true;
        },
        async saveBrand() {
            this.brand.project_id = this.project.id;
            await HTTP.post('/create-site-brand', this.brand)
            this.dialogAddBrand = false;
            await this.getSiteBrands();
        },
        async getBrands() {
            try {
                let { data } = await HTTP.post('/get-all-brands');
                this.brands = data?.content || [];
            } catch (error) {
                console.warn(error);
            }
        },


    },
}
</script>


<style>
.add-user__button {
    position: fixed;
    bottom: 20px;
    right: 20px;
}
</style>
